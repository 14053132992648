@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto-condensed.regular.eot');
  src: url('../fonts/roboto-condensed.regular.woff2') format('woff2'),
       url('../fonts/roboto-condensed.regular.woff') format('woff'),
       url('../fonts/roboto-condensed.regular.ttf') format('truetype'),
       url('../fonts/roboto-condensed.regular.svg#Roboto-Regular') format('svg'),
       url('../fonts/roboto-condensed.regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans.eot');
  src: url('../fonts/OpenSans.woff2') format('woff2'),
       url('../fonts/OpenSans.woff') format('woff'),
       url('../fonts/OpenSans.ttf') format('truetype'),
       url('../fonts/OpenSans.svg#Roboto-Regular') format('svg'),
       url('../fonts/OpenSans.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Light.eot');
  src: url('../fonts/Roboto-Light.woff2') format('woff2'),
       url('../fonts/Roboto-Light.woff') format('woff'),
       url('../fonts/Roboto-Light.ttf') format('truetype'),
       url('../fonts/Roboto-Light.svg#Roboto-Regular') format('svg'),
       url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.woff2') format('woff2'),
       url('../fonts/Roboto-Regular.woff') format('woff'),
       url('../fonts/Roboto-Regular.ttf') format('truetype'),
       url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg'),
       url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.eot');
  src: url('../fonts/Roboto-Medium.woff2') format('woff2'),
       url('../fonts/Roboto-Medium.woff') format('woff'),
       url('../fonts/Roboto-Medium.ttf') format('truetype'),
       url('../fonts/Roboto-Medium.svg#Roboto-Regular') format('svg'),
       url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto-condensed.light.eot');
  src: url('../fonts/roboto-condensed.light.woff2') format('woff2'),
       url('../fonts/roboto-condensed.light.woff') format('woff'),
       url('../fonts/roboto-condensed.light.ttf') format('truetype'),
       url('../fonts/roboto-condensed.light.svg#Akrobat-Black') format('svg'),
       url('../fonts/roboto-condensed.light.eot?#iefix') format('embedded-opentype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto-condensed.bold.eot');
  src: url('../fonts/roboto-condensed.bold.woff2') format('woff2'),
       url('../fonts/roboto-condensed.bold.woff') format('woff'),
       url('../fonts/roboto-condensed.bold.ttf') format('truetype'),
       url('../fonts/roboto-condensed.bold.svg#Akrobat-Black') format('svg'),
       url('../fonts/roboto-condensed.bold.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.eot');
  src: url('../fonts/Roboto-Bold.woff2') format('woff2'),
       url('../fonts/Roboto-Bold.woff') format('woff'),
       url('../fonts/Roboto-Bold.ttf') format('truetype'),
       url('../fonts/Roboto-Bold.svg#Akrobat-Black') format('svg'),
       url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Officina';
  src: url('../fonts/OfficinaSansBookC.eot');
  src: url('../fonts/OfficinaSansBookC.woff2') format('woff2'),
       url('../fonts/OfficinaSansBookC.woff') format('woff'),
       url('../fonts/OfficinaSansBookC.ttf') format('truetype'),
       url('../fonts/OfficinaSansBookC.svg#Akrobat-Black') format('svg'),
       url('../fonts/OfficinaSansBookC.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik-Medium.eot');
  src: url('../fonts/Rubik-Medium.woff2') format('woff2'),
       url('../fonts/Rubik-Medium.woff') format('woff'),
       url('../fonts/Rubik-Medium.ttf') format('truetype'),
       url('../fonts/Rubik-Medium.svg#Akrobat-Black') format('svg'),
       url('../fonts/Rubik-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Rubik';
  src: url('../fonts/Rubik-Bold.eot');
  src: url('../fonts/Rubik-Bold.woff2') format('woff2'),
       url('../fonts/Rubik-Bold.woff') format('woff'),
       url('../fonts/Rubik-Bold.ttf') format('truetype'),
       url('../fonts/Rubik-Bold.svg#Akrobat-Black') format('svg'),
       url('../fonts/Rubik-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
}
html {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-style: normal;
  background: #fff;
  color: #333333;
  overflow-x: hidden;
}
body,div, p,h1,h2,h3,h4,h5,span,ul,li {
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding: 0;
}
h1,h2,h3,h4 {
	font-size: 90px;
	 font-family: 'Roboto';
	 font-weight: bold;
}
a:active, a:focus, input, button:active, button:focus, input:focus, input:active, button, a {
 outline: none;
}
a {
	text-decoration: none;
	display: block;
	padding: 0px;
	font-size: 16px;
	font-family: 'Roboto';
	color: #000;
}
a:hover {
	color: #007edb;
}
input::-webkit-input-placeholder {
  font-weight: 200;
  font-family: Roboto;
  text-transform: uppercase;
  font-size: 13.5px;
  letter-spacing: 1.1px;
}
input::-moz-placeholder { 
  font-weight: 200;
  font-family: Roboto;
  text-transform: uppercase;
  font-size: 13.5px;
  letter-spacing: 1.1px;
}
input:focus::-webkit-input-placeholder { color:transparent; }
.cell {
  max-width: 1180px;
/*  padding: 0 5%;*/
  margin:0 auto;
}
.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.width_1 {
	width: 100%;
}
.t_center {
	text-align: center;
}
.header_deposit {
	max-width: 600px; 
	margin: auto;
	padding-top: 0px;
}
.header_deposit_item {
	width: 30%;
	text-align: center;
}
.header_deposit {
	justify-content: space-around;
}
.header_deposit_item a {
	color: #000;
	text-decoration:underline;
}
.header_deposit_item a:hover {
	color: #66cc33;
}

.header_deposit_item {
	padding-bottom: 40px;
}
.menuadaptiv {
  display: none;
}
$blue:#007edb;
$red:#ff3333;
.header {
  background: url(../img/back1.png) no-repeat top center;
  background-size: cover;
  padding: 2% 0 0% 0;
  position: relative;
  z-index: 100;
}
.head_menu_btn {
  cursor: pointer;
  display: none;
}
.head_menu {
	justify-content: center;
	align-items: center;
}
.menu a {
	padding: 10px;
	font-size: 18px;
	border-bottom: 1px solid transparent;
	transition: 0.2s;
	font-weight: 300;
	font-size: 14px;
}
.menu a:hover {
	border-bottom: 1px solid $red;
	transition: 0.2s;
	color: $red;
}
.menu li {
	display: inline-block;
}
.head_call {
	align-items: flex-end;
}
.head_call span {
	margin-bottom: 10px;
	color: #000;
	margin-right: 20px;
	font-family: OpenSans;
	font-size: 1.2rem;
}
.btn_call {
  border-radius: 24px;
  background-color:$red;
  padding:11px 25px;
  align-items: center;
  width: 190px;
}
.btn_call a {
	color: #fff;
	font-weight: 200;
	font-size: 14px;
}
.btn_call:hover {
	transition: 0.2s;
	box-shadow: 0px 10px 29px 0px rgba(0,0,0, 0.1);
	cursor: pointer;
	background: #F00000;
}
.head_logo {
	display: flex;
	align-items: center;
}
.head_logo,
.head_call {
	width: 25%;
}
.head_menu {
	width: 50%;
}
.btn_download {
	background: $red;
  width: 281px;
  height: 66px;
  border-radius: 34px;
  color: #fff;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 300;
  line-height: 67px;
  text-align: center;
  margin: auto;
  transition: .2s;
   border: 1px solid rgba(255,255,255,.4);
   position: relative;
   cursor: pointer;
}
.btn_download:hover {
	color:#fff;
	box-shadow: 0px 10px 29px 0px rgba(0,0,0, 0.18);
	background: #f33;
}
.header_middle {
	padding: 60px 0;
	padding-left: 5%;
	padding-bottom: 0;
}
.header_headline p {
	font-size: 24px;
}
.header_headline span {
	font-size: 63.27px;
	color: #ff3333;
	font-family: Roboto;
	font-weight: 500;
}
.header_headline > span:nth-child(3) {
	font-size: 48px;
	font-weight: 400;
	padding-bottom: 10px;
}
.header_headline > p:first-child {
	font-size: 1rem;
	font-weight: 300;
	padding-bottom: 30px;
}
.header_bottom p {
	font-size: 14px;
	color: black;
	font-weight: 100;
	font-family: 'Roboto';
	padding-bottom: 20px;
}
.header_bottom {
	padding-bottom: 90px;
	padding-top: 7%;
}
.header_bottom .btn_download {
	margin-left: 0;
}
.history .main_headline {
	display: flex;
	align-items: center;
	padding-bottom: 50px;
	padding-top: 3%;
}
.history .main_headline img {
	padding-right: 40px;
}
.slick-current .slider_item4 {
	padding-top: 43%;
}
.history {
	background: url(../img/back2.png) no-repeat center 66px;
	background-size: cover;
	position: relative;
}
.history_slider_item {
	max-width: 226px!important;
}
.history_slider_item span {
	font-size: 1.75rem;
	color: $blue;
	font-weight: 400;
	padding-bottom: 10px;
}
.history_slider_item li {
	font-size: 14px;
	font-weight: 200;
}
.slider_item1,
.slider_item5 {
	padding-top: 37%;
}
.slider_item2,
.slider_item6 {
	padding-top: 13%;
}
.slider_item4,
.slider_item8 {
	padding-top: 0%;
}
.slider_item3,
.slider_item7{
	padding-top: 14%;
}
.click_slider .slider_item4 {
	padding-top: 100px;
}
.history_slider {
	position: relative;
}
.arrow_left {
	position: absolute;
	cursor: pointer;
	left: -5%;
	z-index: 10;
	top: 10%;
}
.arrow_right {
	position: absolute;
	cursor: pointer;
	z-index: 10;
	right: -5%;
	top: 10%;
	transform: rotate(180deg);
}
.history_partner {
	padding-top: 63px;
}
.history_partner_head {
	font-size: 2rem;
	color: #0066cc;
	text-align: center;
	padding-bottom: 50px;
}
.history_partners_item {
	width: 21%;
	font-weight: 200;
	font-size: 14px;
	line-height: 20px;
}
.history_partners_item_img {
	height: 118px;
	display: flex;
	align-items: flex-end;
	margin-bottom: 20px;
}
.history_partners_item span {
	font-weight: 400;
}
.history_partners_item .partner_date {
	font-weight: 200;
	padding-top: 20px;
}
.history_partner_bottom {
	font-size: 1.5rem;
	text-align: center;
	padding-bottom: 100px;
}
.history_arrow {
	position: absolute;
	bottom: -26px;
	left: 47%;
}
.money .support_items {
	padding: 70px 0;
}
.money {
	padding: 6% 0;
}
.programs {
	background: url(../img/back3.png) no-repeat center center;
	background-size: cover;
	position: relative;
	max-width: 1360px;
	margin: auto;
	padding: 80px;
}
.programs_left {
	width: 60%;
}
.programs_left .main_headline {
	max-width: 405px;
}
.programs_left > p {
	max-width: 340px;
	padding-top: 25px;
}
.programs_left li {
	padding-top: 25px;
	font-weight: 200;
	max-width: 610px;
}
.programs_right {
	position: absolute;
	top: -12%;
	right: 0%;
	width: 60%;
}
.programs_right img {
	width: 100%;
	max-width: 873px;
}
.slick-slide > div {
	display: flex;
	justify-content: center;
}
.instruction {
	padding: 100px 0;
}
.instruction_cont {
	background-color: #F7F7F7;
	padding: 6%;
	position: relative;
}
.instruction_left {
	width: 50%;
}
.instruction_left p {
	font-weight: 300;
	font-size: 1.125rem;
	line-height: 29px;
	padding-bottom: 40px;
}
.instruction_left p:last-child {
	padding-bottom: 0;
}
.instruction_right {
	width: 50%;
	max-width: 721px;
	position: absolute;
	top: -12%;
	right: 5%;
}
.instruction_right img {
	width: 100%;
}

.packets .main_headline {
	padding-left: 30%;
	padding: 40px 0 40px 360px;
	position: relative;
	background: #F1FBFF;
	z-index: -1;
}
.packets .main_headline img {
	position: absolute;
	left: -10%;
	top: -150px;
	max-width: 594px;
}
.packets_table {
	padding-top: 50px;
}
.packets_table table{
	width: 100%;
}
.packets_table b {
	font-weight: 300;
	font-size: 1.25rem;
	padding-bottom: 20px;
	display: block;
}
.packets_table .b1 {
	font-size: 1rem;
	padding-left: 40px;
	text-align: left;
	padding-bottom: 0;
}
.vert_bot {
	vertical-align: bottom;
	width: 37%;
}
.packets_table span {
	font-size: 1rem;
	font-weight: 300;
}
.packets_table table tr:first-child {
	padding-bottom: 30px;
}
.packets_table .td_blue {
	font-size: 14px;
	color: #0099cc;
}
.packets_table th {
	padding-bottom: 30px;
}
.packets_table td {
	font-size: 14px;
	font-weight: 100;
	padding:5px;
	border:transparent;
}
.packets_table tr:nth-child(2n) {
  background: #F1FBFF;
}
.packets_table td:first-child {
	padding-left: 40px;
}
.packets_table tr:last-child {
  background: transparent;
}
table {
	border-collapse: collapse;
}
.packets_table .td_big {
	font-size: 16px;
	font-weight: 300;
}
.packets {
	padding-top: 10%;
	padding-bottom: 120px;
}
.packets_table .btn_download {
	width: 200px;
}
.gain {
	background: url(../img/back5.png) no-repeat center center;
	padding-top: 80px;
	padding-bottom: 70px;
}
.gain .cell {
	max-width: 940px;
}
.gain .main_headline {
	padding-bottom: 60px;
}
.gain_block {
	justify-content: flex-start;
	position: relative;
}
.gain_block:before {
	content: "";
	width: 607px;
	position: absolute;
	left: -12%;
	top: 3%;
	height: 454px;
	background: url(../img/shape2.png) no-repeat center center;
}
.gain_item {
	font-weight: 300;
	font-size: 1rem;
	padding: 10px 30px;
	position: relative;
	line-height: 25px;
	margin-bottom: 60px;
}
.gain_item span {
	font-weight: 200;
}
.gain_item:first-child,
.gain_item:nth-child(4) {
	padding-left: 0;
}
.gain_item:nth-child(4) {
	width: 100%;
}
.gain_item:nth-child(5) {
	padding-left: 100px;
}
.gain_item:nth-child(6) {
	bottom: 23%;
	left: 56%;
	position: absolute;
	margin-bottom: 0;
	padding: 0;
	font-weight: 300;
	font-size: 1.25rem;
}
.gain_item .header_bottom {
	padding: 0;
	padding-top: 20px;
}
.after_gain:after {
	width: 1px;
	height: 100%;
	background: #000;
	content: "";
	position: absolute;
	right: 0;
	top:0;
}
.five {
	padding-top:5%;
}
.five .cell > .main_headline{
	padding-top: 3%;
}
.five_head {
	max-width: 980px;
	margin: auto;
}
.five_head .main_headline span {
	font-size: 1rem;
	font-weight: 300;
	text-transform: inherit;
	color: #000;
	font-family: Roboto;
}
.five_head .main_headline {
	width: 70%;
	padding-top:10%;
}
.five_head .main_headline p {
	padding-bottom: 10px;
}
.five_head .main_headline span {
	line-height: 25px;
}
.five_2012 {
	max-width: 1130px;
	margin: auto;
	background: #EBF9FF;
	padding: 5%;
	justify-content: space-between;
	margin-top: 7%;
}
.five_2012 span {
	width: 100%;
	text-align: center;
	font-weight: 300;
	font-size: 1.25rem;
	padding-bottom: 30px;
}
.five_2012_item {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}
.five_2012_item img {
	padding-right: 20px;
}
.five .money_block {
	max-width: 970px;
	margin: auto;
	padding:5% 5% 7% 5%;
	background: url(../img/redback.png) no-repeat center bottom;
	background-size: cover;
	margin-bottom: 5%;
}

.license {
	background: #EBF9FF;
	padding: 5% 0 0 0;
}
.license p {
	text-align: center;
	font-weight: 400;
	font-size: 2rem;
	padding-bottom: 60px;
}
.footer_form input {
    padding: 19.5px 0px 19.5px 20px;
    width: 288px;
    border-radius: 30px;
    border: none;
    background: #eff0f4;
    margin-bottom: 25px;
}
.footer_form {
	max-width: 650px;
	margin: auto;
}
.footer_form span {
	color:#333333;
	font-size: 1rem;
	font-weight: 100;
	text-align: center;
	padding: 30px 0;
}
.step_item_m {
	display: none;
}
.packets_table_img {
	display: none;
}
.statist {
	background: #333331;
	padding: 7% 0;
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
clip-path: polygon(0 0, 100% 0, 100% 100%, 0 85%);
}
.statist .cell {
	max-width: 940px;
}
.statist_block > p {
	color: #cccccc;
	max-width: 309px;
	padding-top: 20px;
}
/*.statist_block:after {
	max-width: 831px;
	height: 431px;
	content: "";
	background: url(../img/graphika.png) no-repeat center center;
	background-size: contain;
	width: 100%;
	display: block;
}*/
.statist_block_img {
	width: 100%;
	text-align: right;
	margin-top: -160px;
}
.statist_block_bottom {
	text-align: center;
	color: #666666;
	font-weight: 200;
	font-size: 1rem;
	padding-top: 6%;
}
.main_headline .head_gray {
	color: #333333;
	padding-top: 10px;
}
.medium_statist {
	background: url(../img/back2.png) no-repeat center center;
	padding: 7% 0;
	padding-top: 20%;
	position: relative;
	margin-top: -17%;
}
.medium_statist .statist_block {
	max-width: 615px;
	width: 50%;
	margin-left: auto;
}
.medium_statist_item {
	width: 48%;
	font-weight: 500;
	font-size: 1.25rem;
	padding-top: 8%;
	color: #333333;
}
.medium_statist_item span {
	position: relative;
}
.medium_statist_item p {
	font-size: 1rem;
	padding-top: 5px;
	font-weight: 400;
}
.medium_statist_item span:before {
	position: absolute;
	display: block;
	content: "";
	width: 19px;
	height: 22px;
	background: url(../img/dot.png) no-repeat center center;
	left: -35px;
}
.medium_statist_img {
	position: absolute;
	left: 0;
	bottom:0; 
	width: 60%;
	height: 100%;
	background: url(../img/people.png) no-repeat center 150%;
	background-size: contain;
}
.individ {
	background: #333331;
	padding: 3% 0;
	text-align: center;
	color: #fff;
	font-size: 1.25rem;
	font-weight: 300;
}
.money_block {
	max-width: 622px;
	margin: auto;
}
$gray:#333333;
.money .main_headline p {
	color: $gray;
	padding: 0;
	background: none;
	font-weight: normal;
	font-size: 1.75rem;
	margin: auto;
}
.metodik {
	background: #F6F6F6;
	padding: 6% 0;
	position: relative;
	margin-top: 0;
}
.metodik .statist_block {
	margin-left: 0;
	max-width: 470px;
	width: 60%;
}
.metodik .medium_statist_item {
	width: 100%;
}
.metodik .medium_statist_item p {
	font-size: 14px;
}
.metodik .medium_statist_item {
	font-size: 1.125rem;
	font-weight: 600;
}
.metodik_img {
	position: absolute;
	width: 50%;
	right: 0;
	top: -5%;
	max-width: 725px;
}
.metodik_img img {
	width: 100%;
}
.back2{
	background: url(../img/back3.png) no-repeat right bottom,
	url(../img/back3.png) no-repeat top center;
	background-size: 200%, 103%;
	padding-bottom: 9%;
}
.five .main_headline p {
    color: #333;
    padding: 0;
    background: 0 0;
    font-weight: 400;
    font-size: 1.75rem;
    margin: auto;
}
.five .support_items  {
	max-width: 730px;
	margin: auto;
}
.five .support_item {
	width: 20%;
}
.five .support_item p {
	color: #fff;
}
.five .support_item_img {
	height: 65px;
}
.five .main_headline {
	padding-bottom: 4%;
}
.five_table img {
	padding-left: 6%;
}
.license .metodik_img {
	width: 40%;
}
.license .statist_block {
	max-width: 666px;
	width: 60%;
}
.license b {
	font-size: 0.85rem;
	font-weight: 400;
	padding: 20px 0;
	display: block;
}
.license .medium_statist_item {
	padding-top: 5%;
}
.license .metodik_img {
	width: 372px;
	top: 0;
}
.license .cell {
	position: relative;
}
.license .partership_btn {
	margin-left: 0;
	text-align: left;
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	max-width: 640px;
}
.license .partership_btn span {
	padding:0;
	max-width: 323px;
	margin: 0;
}
.license .partership_btn .btn_download {
	margin: 0;
}
.license_msg {
	background: url(../img/buble.png) no-repeat bottom center;
	background-size: cover;
	padding: 5% 5% 9% 5%;
	text-align: center;
	width: auto;
	font-weight: 400;
	font-size: 1.2rem;
	color: #666666;
	margin-top: 50px;
	margin-left: -30px;
}
.license {
	background: #fff;
}
.start_b .step_item p:nth-child(3) {
	color: $gray;
	padding-top: 15px;
	max-width: 220px;
}
.start_b .step_number {
	font-size: 90px;
	color: #FDE9E8;
	left: -14%;
}
.start_b .step_item {
	max-width: 220px;
}
.start_b .main_headline p {
	background: transparent;
	color: $gray;
	text-align: center;
	margin-left: 0;
}
.start_b .main_headline {
	padding-bottom: 8%;
}
.communcation .main_headline {
	text-align: center;
}
.communcation .main_headline p {
	    background: 0 0;
    color: #333;
    text-align: center;
    margin-left: 0;
    text-align: center;
    max-width: 630px;
    padding: 0;
}
input {
  box-shadow: inset 0px 2px 7px 0px rgba(0, 0, 0, 0.3);
}
input:active, input:focus {
	box-shadow: none;
}
.communcation {
	background: url(../img/back5.png) no-repeat left center;
	background-size: cover;
	padding: 12% 0 5% 0;
	margin-top: -10%;
}
#modal_3 p,
#modal_4 p,
#modal_5 p {
	text-align: left;
}
#modal_3 span,
#modal_4 span,
#modal_5 span {
	font-weight: 600;
	color: $gray;
	padding-bottom: 15px;
}
#modal_3 .modalhead,
#modal_4 .modalhead,
#modal_5 .modalhead {
	font-weight: 500;
}
.table_png {
	display: none;
}
































.about {
	background: url(../img/Image_BG.png) no-repeat bottom right;
	background-size: contain;
	padding-bottom: 200px;
}
.about_cont {
	background:url(../img/BG_Blue.png) no-repeat center center;
	border-radius: 25px;
	background-size: cover;
	color: #fff;
	position: relative;
	justify-content: space-between;
	padding: 80px;
}
.about_banner {
	width: 50%;
	position: relative;
}
.about_banner img {
	position: absolute;
	max-width: 498px;
	width: 70%;
	top: -75%;
	left: 8%;
	z-index: 10;
}
.about_banner p {
	width: 47%;
	z-index: 100;
	position: absolute;
	font-size: 32px;
	text-align: center;
	right: 33%;
	top: -20%;
}
.about_banner p span {
	font-size: 38px;
	font-weight: bold;
	text-transform: uppercase;
}
.about_text {
	width: 50%;
}
.about_text p {
	padding-bottom: 25px;
}
.about_text p:last-child {
	padding-bottom: 0;
}
.about_text span {
	display: inline-block;
	padding-right: 10px;
}
.giroscafe {
	position: relative;
	background: url(../img/back2.png) no-repeat center top;
}
.giroscafe_head {
	color: #fff;
	font-size: 27px;
	margin: auto;
	text-align: center;
	padding-top: 150px;
}
.giroscafe_sms {
	background: url(../img/mes1.png) no-repeat center center;
	background-size: contain;
	max-width: 385px;
	padding: 65px 40px 45px 40px;
	text-align: center;
	margin-top: 100px;
	margin-left: 5%;
}
.giroscafe_sms span {
	color: $blue;
	font-size: 24px;
}
.giroscafe_sms p {
	margin-top: -10px;
}
.giroscafe_bottom {
	padding: 100px;
}
.clock {
	padding-top: 100px;
}
.clock_img {
	width: 35%;
}
.clock_img img {
	width: 100%;
}
.clock_block {
	width: 65%;
}
.clock_block_headline {
	font-size: 44px;
	font-family: Rubik;
	font-weight: bold;
	text-transform: uppercase;
	color: $blue;
}
.clock_block_text p:before {
	display: inline-block;
	margin-right: 10px;
	color:$blue;
	font-size: 24px;
	position: absolute;
	content:"";
	width: 11px;
	top: 4px;
	left: 2px;
	height: 12px;
	background: url(../img/zv.png) no-repeat center center;
}
.clock_block_text {
	padding: 30px 0;
}
.clock_block_text p {
	padding-bottom: 15px;
	position: relative;
	padding-left: 30px;
}
.clock .btn_download {
	margin-left: 25px;
}
.name {
	background: url(../img/back3.png) no-repeat center top;
	background-size: cover;
	padding: 270px;
	position: relative;
	margin: 60px 0 100px 0;
}
.name img {
	position: absolute;
	top: -10%;
	left: -5%;
	width: 90%;
}
.name .cell {
	position: relative;
}
.name_block {
	position: absolute;
	right: 0;
	top: 45%;
	width: 40%;
}
.name_block span {
	font-size: 30.2px;
	margin-bottom: 20px;
}
.name_block p:before {
	display: inline-block;
	margin-right: 10px;
	color:$blue;
	font-size: 24px;
	position: absolute;
	content:"";
	width: 11px;
	top: 4px;
	left: 2px;
	height: 12px;
	background: url(../img/zv.png) no-repeat center center;
}
.name_block p {
	font-size: 17px;
	width: 65%;
	position: relative;
	padding-left: 25px;
}
.main_headline span {
	font-weight: 400;
	font-size: 1.56rem;
	color:#fff;
	padding-top: 15px;
}
.main_headline p {
	font-size: 1.75rem;
	font-weight:400;
	background: $red;
	padding: 10px 30px;
	color: #fff;
	display: inline-block;
	margin-left: -30px;
}
.partner_block {
	padding-top: 30px;
}
.partner_block_item {
	width: 23%;
}
.partner_block_item_img {
	height: 110px;
	    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding-bottom: 10px;
}
.partner_block_item p {
	font-size: 0.83rem;
	max-width: 208px;
}
.partner_block_mess {
	width: 25%;
	background: url(../img/mes2.png) no-repeat center top;
	background-size: contain;
	margin-top:90px;
}
.partner_block_mess p {
	padding: 30px 15px;
	color: #fff;
}
.step {
	background: $gray;
	padding-bottom: 2%;
	padding-top: 1px;
}
.start_b {
	background: #fff;
}
.step .cell {
	max-width: 1016px;
}
.start_b .cell {
	max-width: 877px;
}
.step_item {
	width: 32%;
	position: relative;
	padding-bottom: 70px;
	position: relative;
	z-index: 10;
}
.step_item:nth-child(3) p:nth-child(3){
	max-width: 100%;
}
.step_item_img {
	height: 60px;
	display: flex;
	align-items: flex-end;
}
.step_number {
	position: absolute;
	top: 21%;
	left: -13%;
	font-size: 110px;
	font-weight: 300;
	color: #5C342F;
	z-index: -1;
}
.step .main_headline {
	padding-bottom: 10%;
	margin-top: -25px;
}
.start_b .main_headline {
	margin-top: 0;
	text-align: center;
}
.start_b .step_items {
	margin-left: 10%;
}
.step_item span {
	font-weight: 300;
	font-size: 1.25rem;
	padding-top: 20px;
	color: #fff;
}
.step_item p:nth-child(3) {
	color: #fff;
	font-size: 14px;
	font-weight: 300;
	padding-top: 5px;
	line-height: 20px;
	height: 120px;
	max-width: 219px;
}
.step .btn_download {
	background: $red;
	color: #fff;
	max-width: 192px;
	margin-left: 0;
}
.step .btn_download:hover {
	box-shadow: 0px 10px 29px 0px rgba(255,51,51, 0.18);
}
.format_block_item {
	width: 32%;
}
.format_block_item:nth-child(2) {
	width: 50%;
}
.format_block_item_text span {
	font-family: Rubik;
	font-size: 32px;
	color: $blue;
}
.format_block_item_img img {
	padding: 30px 0 20px 0;
}
.format_block_item_text1 {
	padding: 10px 0 15px 0;
}
.format_block_item_text2 {
	font-weight: 200;
}
.format_block_item_textblock .format_block_item_text2 {
	width: 40%;
}
.format_block_item_textblock .format_block_item_text2 b{
	font-weight: normal;
}
.format {
	padding-bottom: 60px;
}
.renta {
	padding: 70px 0; 
	background: url(../img/back5.png) no-repeat center center;
}
.renta_img,
.renta_block {
	width: 48%;
}
.renta_img img {
	width: 100%;
}
.renta_block_text p:before {
    display: inline-block;
    margin-right: 10px;
    color: #007edb;
    font-size: 24px;
    position: absolute;
    content: "";
    width: 11px;
    top: 4px;
    left: 2px;
    height: 12px;
    background: url(../img/zv.png) no-repeat center center;
}
.renta_block_text p {
    padding-bottom: 15px;
    position: relative;
    padding-left: 30px;
}
.renta_block_text {
	padding: 40px 0;
}
.renta .btn_download {
    margin-left: 25px;
}
.sale {
	padding: 100px 0; 
}
.sale_item {
	width: 47.1%;
}
.sale_item > p {
	max-width: 360px;
	font-size: 0.88rem;
}
.sale .main_headline {
	height: 120px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-bottom: 50px;
}
.sale_item_img {
	height: 83px;
	margin-bottom: 10px;
}
.support {
	padding-bottom: 100px;
}
.support_block {
	background: #F8F8F8;
	padding: 70px;
	border-radius: 34px;
}
.support_block .main_headline {
	text-align: center;
	padding-bottom: 50px;
}
.support_item {
	width: 33%;
}
.support_item_img {
	height: 95px;
	text-align: center;
}
.support_item p {
	font-size: 1rem;
	text-align: center;
	font-weight: 300;
	padding-top: 10px;
}
.partership_headline {
	margin: auto;
	text-align: center;
	font-weight: bold;
	font-size: 2rem;
	color: #363637;
	padding-bottom: 30px;
}
.partner_item {
	width: 33%;
	text-align: center;
}
.partner_item span {
	font-size: 1.375rem;
	/*font-weight:bold;*/
	padding: 10px 0;
} 
.partner_item p {
	font-size: 0.88rem;
	max-width: 300px;
	margin: auto;
}
.partership_item_img {
	text-align: center;
	height: 90px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
} 
.partership_btn {
	position: relative;
	text-align: center;
}
.partership_btn img {
	position: absolute;
	left: -70%;
	z-index: 10;
	top:-75px;
}
.partership_btn span {
	font-size: 0.88rem;
	font-weight: 100;
	font-size: #7d7d7d;
	padding-bottom: 10px;
	max-width: 434px;
	margin: auto;
}
.partership_btn .btn_download {
	position: relative;
}
.btn_download {
	letter-spacing: 1.5px;
}
.footer {
	padding: 80px 0;
	background: #2E3133;
}
.footer .main_headline {
	text-align: center;
	color: #fff;
}
.footer .main_headline span {
	color: #fff;
}
.footer_item {
	width: 33%;
}
.footer_item:nth-child(2) {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.footer_item:nth-child(3) {
	text-align: right;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
}
.footer_item span {
	font-weight: 200;
	font-size: 1rem;
	color:#7d7e7f;
	text-align: center;
	padding-bottom: 10px;	
}
.footer_item_social img {
  background-color: rgb(29, 29, 27);
}
.footer_item_social a {
	display: inline-block;
	padding-left: 15px
}
.footer_item a {
	color: #7d7e7f;
	font-weight: 300;
	font-size: 1rem;
	padding-top: 5px;
}
.footer_item a:hover {
	color: $red;
}
.footer_item .btn_download {
	color: #fff;
	padding-top: 0;
}
.footer_item .btn_download:hover {
	color: #fff;
}
.back {
	background: url(../img/Image_BG.png) no-repeat 110% center ;
	background-size: 30%;
}
.modal_about {
	display: none;
	padding: 50px;
	border-radius: 34px !important;
	max-width: 627px;
}
.modal_about .modalhead {
	font-size: 1.5rem;
	font-weight: 200;
	text-align: center;
	padding-bottom: 20px;
}
.modal_about input:first-child {
	width: 100%;
}
.modal_about input:nth-child(2),
.modal_about input:nth-child(3) {
	width: 48%;
}
#modal_4 input:nth-child(3),
#modal_4 input:nth-child(2)  {
	width: 48%!important;
}
.modal_btn {
  width: 330px;
  background: $red;
  height: 66px;
  border-radius: 34px;
  color: #fff;
  font-size: 18px;
  font-family: Roboto;
  text-transform: uppercase;
  line-height: 67px;
  text-align: center;
  margin: auto;
  transition: .2s;
  background-position: 100% 0;
   background-size: 200% 200%;
   border: 1px solid rgba(255,255,255,.4);
   position: relative;
   cursor: pointer;
   font-weight: 200;
   letter-spacing: 1.5px;
}
.modal_btn:hover {
	color:#fff;
	box-shadow: 0px 10px 29px 0px rgba(249,12,0, 0.18);
	background: #F90C00;
}
.modal_about input {
	padding: 16.5px 25px;
	border-radius: 20px;
	border:none;
	background: #EFF0F4;
	margin-bottom: 15px;
}
.fancybox-close-small {
	padding: 0;
	color: $red;
	width: 64px;
}
.fancybox-close-small svg {
	stroke-width:1;
}
.modal_about {
  max-width: 550px;
  border-radius: 4px;
  overflow: hidden;
  
  transform: translateY(-50px);
  transition: all .7s;
}

.modal_about .modalhead,
.modal_about input,
.modal_about p,
.modal_about button {
  transform: translateY(-50px);
  opacity: 0;
  
  transition-property: transform, opacity;
  transition-duration: .4s;
}

/* Final state */
.fancybox-slide--current .modal_about,
.fancybox-slide--current .modal_about .modalhead,
.fancybox-slide--current .modal_about p,
.fancybox-slide--current .modal_about input,
.fancybox-slide--current .modal_about button  {
  transform: translateY(0);
  opacity: 1;
}

/* Reveal content with different delays */

.fancybox-slide--current .modal_about .modalhead {
  transition-delay: .1s;
}
.fancybox-slide--current .modal_about input {
  transition-delay: .3s;
}
.fancybox-slide--current .modal_about p {
  transition-delay: .5s;
}
.fancybox-slide--current .modal_about button  {
  transition-delay: .7s;
}
.order_tel input {
	width: 100% !important;
}
.order_tel .agree,
.modal_about .agree {
	width: auto!important;
}
.modal_about span,
.modal_about a {
	display: inline-block;
	font-family: Roboto;
	font-weight: 200;
	font-size: 15px;
}
.modal_about a {
	padding-left: 3px;
}
.modal_about p {
	width: 100%;
	text-align: center;
}
.order_tel a {
	modal_about: black;
}
.modal_about a:hover {
	color: $blue;
}
.modal_about span {
	color: #C3C4C7;
}
#modal_5 .modal_btn {
	padding: 0 20px;
}
.success {
	max-width: 450px;
	border-radius: 34px;
	padding: 30px;
	background: #fff;
	text-align: center;
	color: black;
}
.success span {
	font-weight: 200;
	font-size: 1.77rem;
}
.success p {
	padding: 20px;
}
.success_text {
	position: relative;
}
.success_text:after {
	position: absolute;
	content: "";
	width: 100px;
	height: 1px;
	background: black;
	bottom: 0px;
	left: 37%;
}
.success_link a {
	display: inline-block;
	padding: 0 10px;
}
#success,
#success2 {
	display: none;
}


input[type=radio] {
    display: none;
}
input[type="radio"] + label span {
    display:inline-block;
    width:30px;
    height:30px;
    margin:-1px 4px 0 0;
    vertical-align:middle;
    background:#FAFAFA;
    cursor:pointer;
    border-radius: 50%;
    display: block;
    margin: auto;
    position: relative;
    margin-top: 10px;
    background: #F8F8F8;
    box-shadow: 0px 0px 37px 3px rgba(0,0,0,0.03) inset;
}
input[type="radio"] + label {
	font-size: .88rem;
    max-width: 300px;
    display: block;
    margin: auto;
}
input[type="radio"]:checked + label span:before {
    background: $blue;
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    top: 6px;
    left: 6px;
}
.partership_calculator {
	margin: auto;
	text-align: center;
	padding-top: 30px;
}
.partership_calculator input {
	padding: 20px 20px;
	width: 120px;
	text-align: center;
	border-radius: 34px;
	border:none;
	background: #F8F8F8;
	box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.05) inset;
	margin: 0 10px;
}
.partership_calculator p {
	padding-top: 30px;
}
.partership_calculator p,
.partership_calculator span {
	font-size: 1.77rem;
	letter-spacing: 1.1px;
}
.partership_calculator span {
	display: inline-block;
}
.head_menu_btn {
	position: relative;
    width: 25.25px;
    height: 20px;
    cursor: pointer;
    margin: 5px;
    text-align: left;
    z-index: 2;
}

.head_menu_btn div {
	background: #000;
    position: absolute;
    height: 4px;
    width: 27px;
    border-radius: 10px;
}
.head_menu_btn div:first-child {
    top: 0;
}
.head_menu_btn div:nth-child(2) {
    top: 7px;
}
.head_menu_btn div:nth-child(3){
    top: 14px;
}
.head_menu_btn:active .head_menu_btn div {
    background: #fff;
}
.activemenu {
	display: block;
}
.media_menu {
	width: 100%;
	height: 90%;
	position: absolute;
	z-index: 100;
	padding-top: 0;
}
.menuadaptiv_close {
    text-align: center;
    margin: 10% auto;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #fff;
    position: relative;
    cursor: pointer;
}
.menuadaptiv_close div:first-child, .menuadaptiv_close div:nth-child(2) {
    width: 25px;
    height: 3px;
    position: absolute;
    background: #fff;
    top: 23px;
    left: 11px;
    border-radius: 5px;
    transform: rotate(45deg);
}
.menuadaptiv_close div:nth-child(2) {
    transform: rotate(135deg);
}
.menuadaptiv a {
	color: #fff;
}
.menuadaptiv a:hover {
	color: $red;
}
.menuadaptiv_close:active {
    border-color: $red;
}
.menuadaptiv_close:active div:first-child, .menuadaptiv_close:active div:nth-child(2) {
    background: $red;
}
.fancybox-can-drag .fancybox-content {
	cursor: unset;
}


    














.menuadaptiv {
position: absolute;
    height: 100vh;
    z-index: 500;
    top: 0;
    width: 100%;
    background: #373737;
    text-align: center;
    display: none;
    opacity: 1;
    transition: .2s;
}
.menuadaptiv a {
  padding: 20px 0;
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
}

.compensate-for-scrollbar {
	margin-right: 0!important;
}